const useSessionUrl = ()=>{
    const isBrowser = "object" !== "undefined";
    const setSessionUrl = (url)=>{
        if (!isBrowser) {
            return;
        }
        sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.setItem("sessionUrl", url);
    };
    const getSessionUrl = ()=>{
        if (!isBrowser) {
            return;
        }
        if (!(sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.getItem("sessionUrl"))) {
            return null;
        }
        return sessionStorage.getItem("sessionUrl");
    };
    const removeSessionUrl = ()=>{
        if (!isBrowser) {
            return;
        }
        sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.removeItem("sessionUrl");
    };
    return {
        setSessionUrl,
        getSessionUrl,
        removeSessionUrl
    };
};
export default useSessionUrl;
