import useRedirect, { REDIRECT_QUERY } from "@/hooks/useRedirect";
import { useAuthStore } from "@/lib/auth/AuthStore";
import { gaSignupCompleted } from "@/lib/ga4";
import { loginCompleted, signupCompleted, updateUserInfoMixpanel } from "@/lib/mixpanels/mixpanel";
import { HOME_PAGE, LOGIN_PAGE, SIGNUP_AFTER_USER_PROFILE } from "@/settings/constant";
import { useMutation } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";
import { getMe } from "src/api/getMe";
import LoginDialog from "src/store/loginDialog";
import { useSavedUserSourceStore } from "src/store/main";
import useSessionUrl from "../../../hooks/useSessionUrl";
import dayjs from "dayjs";
import { fetchAccessToken } from "../../../api/auth";
// 환경설정 분리
const KAKAO_AUTH_CONFIG = {
    REDIRECT_URI: "".concat(process.env.NEXT_PUBLIC_MARKET_API_ENDPOINT, "/auth/kakao/callback"),
    APP_ENDPOINT: process.env.NEXT_PUBLIC_APP_ENDPOINT
};
const useKakaoSignInUp = ()=>{
    const router = useRouter();
    const { query , pathname  } = router;
    const authStore = useAuthStore();
    const { getSessionUrl , setSessionUrl , removeSessionUrl  } = useSessionUrl();
    const { setState  } = LoginDialog((state)=>({
            isOpenSignUpDialog: state.isOpenLoginDialog,
            setState: state.setState
        })) //회원가입, 로그인시 다이얼로그 store
    ;
    const getMeMutation = useMutation(getMe);
    const { redirectAgain , redirectPath  } = useRedirect();
    const fetchAccesTokenMutation = useMutation(fetchAccessToken);
    const { updateUserMeta  } = useSavedUserSourceStore((param)=>{
        let { updateUserMeta  } = param;
        return {
            updateUserMeta
        };
    });
    // 카카오 로그인 핸들러 개선
    const onKakao = useCallback(async ()=>{
        removeSessionUrl();
        redirectPath && setSessionUrl(redirectPath);
        try {
            const { Kakao  } = window;
            await Kakao.Auth.authorize({
                redirectUri: KAKAO_AUTH_CONFIG.REDIRECT_URI,
                state: "".concat(KAKAO_AUTH_CONFIG.APP_ENDPOINT).concat(router.pathname)
            });
        } catch (error) {
            handleError(error);
        }
    }, [
        pathname,
        redirectPath
    ]);
    // 인증 후 처리 로직 개선
    const processKakaoCallback = async (param)=>{
        let { identificationId , signupUser  } = param;
        try {
            const { accessToken  } = await fetchAccesTokenMutation.mutateAsync(identificationId);
            const { user  } = await getMeMutation.mutateAsync(accessToken);
            authStore.updateUser(user);
            updateUserMeta({
                mainSubject: user === null || user === void 0 ? void 0 : user.mainSubject,
                job: user === null || user === void 0 ? void 0 : user.job
            });
            trackUserLogin({
                signupUser,
                user
            });
            handleRedirect(user);
        } catch (error) {
            handleError(error);
        }
    };
    const trackUserLogin = (param)=>{
        let { signupUser , user  } = param;
        {
            (user === null || user === void 0 ? void 0 : user.job) && updateUserInfoMixpanel({
                userProfile: {
                    subject: user === null || user === void 0 ? void 0 : user.mainSubject,
                    userType: user === null || user === void 0 ? void 0 : user.job,
                    school: []
                },
                userId: user.id.toString()
            });
        }
        if (signupUser === "true") {
            signupCompleted({
                user_id: authStore.user.id.toString(),
                "Registration Method": "kakao",
                "Login Method": "kakao",
                $name: authStore.user.displayName,
                $email: authStore.user.email,
                "Phone Number": authStore.user.phoneNumber
            });
            gaSignupCompleted({
                user_id: authStore.user.id.toString(),
                registration_date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                type: "kakao",
                name: authStore.user.displayName
            });
        }
        loginCompleted({
            user_id: authStore.user.id.toString(),
            "Registration Method": "kakao",
            "Login Method": "kakao",
            $name: authStore.user.displayName,
            $email: authStore.user.email,
            "Phone Number": authStore.user.phoneNumber
        });
    };
    const handleRedirect = (user)=>{
        //TODO 세션에 저장한거 보내주기
        const savedRedirectPath = getSessionUrl();
        if (!(user === null || user === void 0 ? void 0 : user.job) && pathname.includes("login")) {
            router.push({
                pathname: "".concat(SIGNUP_AFTER_USER_PROFILE),
                query: savedRedirectPath ? {
                    [REDIRECT_QUERY]: savedRedirectPath,
                    alreadySignupUser: true
                } : {
                    alreadySignupUser: true
                }
            });
            return;
        } else if (!(user === null || user === void 0 ? void 0 : user.job)) {
            router.push({
                pathname: "".concat(SIGNUP_AFTER_USER_PROFILE),
                query: savedRedirectPath && {
                    [REDIRECT_QUERY]: savedRedirectPath
                }
            });
            return;
        }
        if (savedRedirectPath) {
            router.push(savedRedirectPath);
            return;
        }
        router.push(HOME_PAGE);
    };
    const handleError = (status)=>{
        if (status === "409") {
            setState("openSignUpDialogContents", {
                title: "휴대폰 번호로 이미 가입했어요.",
                content: "로그인 페이지에서 ‘휴대폰 번호로 로그인’ 버튼을 클릭해주세요.",
                btnLabel: "로그인하러 가기",
                onOk: ()=>{
                    redirectAgain(LOGIN_PAGE);
                    setState("isOpenSignUpDialog", false);
                }
            });
        } else {
            setState("openSignUpDialogContents", {
                title: "로그인에 실패했어요.",
                content: "다시 시도해주세요. 문제가 지속되면 고객센터로 문의해주세요.",
                btnLabel: "로그인하러 가기",
                onOk: ()=>{
                    redirectAgain(LOGIN_PAGE);
                    setState("isOpenSignUpDialog", false);
                }
            });
        }
        setState("isOpenSignUpDialog", true);
    };
    useEffect(()=>{
        const { signupUser , identificationId , statusCode  } = query;
        //TODO  identificationId 있을경우 로그인 처리
        if (statusCode && statusCode !== "200") {
            handleError(statusCode.toString());
            return;
        }
        if (identificationId) {
            processKakaoCallback({
                signupUser: signupUser.toString(),
                identificationId: identificationId.toString()
            });
        }
    }, [
        query
    ]);
    return {
        onKakao
    };
};
export default useKakaoSignInUp;
